<template>
  <div>
    <br />
    <div class="row align-items-end">
      <div class="col-2">
        <label for="">Référence </label>
        <input
          type="text"
          disabled
          :value="sale.reference"
          class="form-control"
        />
      </div>

      <div class="col-3">
        <label for="">Client   </label>
        <input
          :disabled="true"
          v-if="sale.customer"
          :value="sale.customer.fullName"
          class="form-control"
        />
      </div>

      <div class="col-2">
        <label for="">Date  </label>
        <input :disabled="true" :value="sale.date" class="form-control" />
      </div>

      <div class="col">
        <label for="">Total </label>
        <input
          type="text"
          disabled
          :value="sale.totalPriceTTC"
          class="form-control text-primary border-primary"
        />
      </div>

      <div class="col">
        <label for=""> Payé </label>
        <input
          type="text"
          disabled
          :value="sale.amountPaid"
          class="form-control text-success border-success"
        />
      </div>

      <div class="col">
        <label for=""> Resté </label>
        <input
          type="text"
          disabled
          :value="sale.amountUnpaid"
          class="form-control text-danger border-danger"
        />
      </div>
    </div>

    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-8">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            Je confirme que les informations relatives à la vente sont correctes
            et que je valide la vente. <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-success"
          @click="save(sale.reference)"
          :disabled="!accept ? true : false"
        >
          <i class="bi bi-check-square-fill"></i> Valider
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        (<span class="text-danger">*</span>) : Ce champ est obligatoire.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      accept: false,
    };
  },

  methods: {
    async save(reference) {
      await this.$store.dispatch("sale/validate", reference);
    },
  },

  computed: {
    ...mapGetters("sale", {
      sale: "getSale",
    }),
  },

  mounted() {
    this.$store.dispatch("sale/show", this.$route.params.reference);
  },
};
</script>
